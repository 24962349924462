<template>
  <div>
    <I18nProvider />

    <ThemeHead />

    <Head>
      <Link rel="icon" type="image/x-icon" :href="icon" />
    </Head>

    <PixelScript v-if="store?.facebook_info" :id="store.facebook_info" />

    <NuxtLayout :name="layout">
      <slot />
    </NuxtLayout>

    <PwaPrompt v-if="layout == 'domain'" />
  </div>
</template>

<script setup lang="ts">
import type { StoreWithDomain } from '~/server/routes/nuxt-api/domains.get'
import { useDomainCategoriesStore } from '~/store/domain_categories'
import type { City, Store } from '~/composables/useMenuModel'
const { storeModel } = useMenuModel()
const { uuid: uuidTemp, setNewUuid } = useCartStore()
const uuid = uuidTemp || setNewUuid()
const props = withDefaults(
  defineProps<{
    withErrors?: boolean
  }>(),
  {
    withErrors: false
  }
)

const { data: domains } = await useFetch<StoreWithDomain[]>('/nuxt-api/domains')
useState('valid_domains', () => domains.value?.map((el) => el.domain))

const { layout, host } = useDomainHost()
const { store, setStore } = useDomainState()
const locale = useNuxtApp().$i18n?.locale?.value
const { setCities } = useCities()

if (!props.withErrors && layout === 'domain') {
  const { data: domainStore, error } = await useFetch(
    '/nuxt-api/laravel/store',
    {
      query: {
        app_locale: useNuxtApp().$i18n?.locale?.value,
        id: host,
        client_uuid: uuid || undefined,
        domain: useDomainHeader()
      },

      transform: (data: SuccessResponse<Store>) => data.data
    }
  )
console.log(domainStore);
console.error(error);
if (domainStore.value) {
    const { data: dataTemp } = await useApiFetch(
      `/cart/${domainStore.value.id}/dose-user-have-table`,
      {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value,
          client_uuid: uuid || undefined
        }
      }
    )
    domainStore.value.is_user_have_table = dataTemp.value?.data
      ?.dose_user_have_table as any
    domainStore.value = storeModel(domainStore.value)
  }
  setStore(domainStore.value)

  if (error.value) {
    createError(error.value)
  }

  if (domainStore.value) {
    await useDomainCategoriesStore().fetchCategories(domainStore.value)
    await useMaterialDefinitionsStore().fetchMaterialDefinitionsStore(
      domainStore.value
    )
    await useStoresSettings().fetchSettingsStore(domainStore.value)
  }
}

const icon = computed(() => {
  if (layout === 'domain') {
    return store.value?.icon
  }

  return '/favicon.ico'
})

if (!props.withErrors && layout !== 'domain') {
  const { data: citiesTemp } = await useNuxtApiFetch<City[]>(
    '/laravel/settings/cities',
    {
      query: {
        query: { app_locale: locale },
        key: 'nuxt-api-laravel-settings-cities-' + locale
      }
    }
  )

  setCities(citiesTemp.value ?? [])
}

const { data } = await useFetch<GlobalSettings>('/nuxt-api/laravel/settings', {
  query: {
    domain: useDomainHeader(),
    app_locale: locale
  },
  key: 'nuxt-api-laravel-settings-index-' + locale
})

const { setSettings } = useSettings()
setSettings(data.value ?? {})
</script>
